import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import cardimg6 from "../Images/Cardimg6.svg";
import cardimg1 from "../Images/cardimg1.svg";
import cardimg2 from "../Images/cardimg2.svg";
import cardimg3 from "../Images/cardimg3.svg";
const VerticalCard = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [hoveredTab, setHoveredTab] = useState(null);

  const tabNames = [
    "01 Direct JSON Changes",
    "02 Real-Time Code Overrides",
    "03 Speed Optimization",
    "04 Dynamic Schema and Sitemaps",
  ];

  const tabDescriptions = [
    "Gain exclusive control over SEO elements at the JSON level. Tweak meta tags, canonical URLs, and structured data directly, allowing you to create a custom SEO framework that aligns perfectly with your goals",
    "Update, adjust, and test SEO improvements with real-time code overrides. No need for bulky plugins or third-party dependencies—our tool allows instant changes that search engines will notice and reward.",
    "SEO isn’t just about keywords; it’s about speed and performance. With code-level access, you can streamline loading times, reduce heavy scripts, and ensure a seamless user experience.",
    "Implement structured data and automate sitemap generation directly from your site’s backend, ensuring every page is crawled, indexed, and ready to climb the ranks.",
  ];

  const tabCards = {
    tab1: [
      {
        id: 1,
        title: "Card 1 for Direct JSON Changes",
        content: "Content for card 1 on Direct JSON Changes",
        image: (
          <img
            className="img-fluid"
            src={cardimg6}
            alt="Direct JSON Changes"
            style={{ borderRadius: "0.5rem" }}
          />
        ),
      },
    ],
    tab2: [
      {
        id: 1,
        title: "Card 1 for Real-Time Code Overrides",
        content: "Content for card 1 on Real-Time Code Overrides",
        image: (
            <img
            className="img-fluid"
            src={cardimg1}
            alt="Direct JSON Changes"
            style={{ borderRadius: "0.5rem" }}
          />
        ),
      },
    ],
    tab3: [
      {
        id: 1,
        title: "Card 1 for Speed Optimization",
        content: "Content for card 1 on Speed Optimization",
        image: (
            <img
            className="img-fluid"
            src={cardimg2}
            alt="Direct JSON Changes"
            style={{ borderRadius: "0.5rem" }}
          />
        ),
      },
    ],
    tab4: [
      {
        id: 1,
        title: "Card 1 for Dynamic Schema and Sitemaps",
        content: "Content for card 1 on Dynamic Schema and Sitemaps",
        image: (
            <img
            className="img-fluid"
            src={cardimg3}
            alt="Direct JSON Changes"
            style={{ borderRadius: "0.5rem" }}
          />
        ),
      },
    ],
  };

  const handleMouseEnter = (tab, index) => {
    setActiveTab(tab);
    setHoveredTab(index);
  };

  const handleMouseLeave = () => {
    setHoveredTab(null);
  };

  return (
    <div className="container ">
      <div className="row">
        {/* Tabs */}
        <div className="col-md-6 py-5">
          <div className="list-group custom-tabs">
            {tabNames.map((name, index) => (
              <div
                key={`tab${index + 1}`}
                className="custom-tab-wrapper"
                onMouseEnter={() => handleMouseEnter(`tab${index + 1}`, index)}
                onMouseLeave={handleMouseLeave}
              >
                <button
                  className={`list-group-item custom-tab ${
                    activeTab === `tab${index + 1}` ? "active-tab" : ""
                  }`}
                >
                  {name}
                </button>
                {hoveredTab === index && (
                  <p className="tab-description">{tabDescriptions[index]}</p>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Content */}
        <div className="col-md-6">
          <div
            className="content-wrapper"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              height: "100%",
              overflow: "hidden",
            }}
          >
            {tabCards[activeTab].map((card) => (
              <div key={card.id} className="image-container">
                {card.image}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalCard;

import React, { useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../Images/logo.svg";
import Banner from "../Images/Graphics.svg";
import Banner3 from "../Images/banner3.svg";
import { Button, Container } from "react-bootstrap";
import icon1 from "../Images/cardicon1.svg";
import icon2 from "../Images/cardicon2.svg";
import icon3 from "../Images/cardicon3.svg";
import icon4 from "../Images/cardicon4.svg";
import cardimg1 from "../Images/cardimg1.svg";
import cardimg2 from "../Images/cardimg2.svg";
import cardimg3 from "../Images/cardimg3.svg";
import cardimg4 from "../Images/cardimg4.svg";
import cardimg5 from "../Images/cardimg5.svg";
import TabWithCards from "./Slider";
import Contactus from "./Contact";
import Verticalcard from "./Verticalcard";
const First = () => {
  const sections = {
    intro: useRef(null),
    tools: useRef(null),
    contact: useRef(null),
    services: useRef(null),
    features:useRef(null),
  };
  const handleGetStartedClick = () => {
    sections.contact.current?.scrollIntoView({ behavior: "smooth" });
  };
  
  useEffect(() => {
    const observerOptions = {
      root: null,
      threshold: 0.4,
    };
  
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const sectionId = entry.target.getAttribute("data-section");
          console.log(`Intersecting: ${sectionId}`); 
          window.history.replaceState(null, "", `/#${sectionId}`);
        }
      });
    };
  
    const observer = new IntersectionObserver(observerCallback, observerOptions);
  
    Object.values(sections).forEach((sectionRef) => {
      if (sectionRef.current) {
        observer.observe(sectionRef.current);
        
        // Trigger for the initial state
        if (sectionRef.current.getBoundingClientRect().top < window.innerHeight) {
          const sectionId = sectionRef.current.getAttribute("data-section");
          window.history.replaceState(null, "", `/#${sectionId}`);
        }
      }
    });
  
    return () => {
      Object.values(sections).forEach((sectionRef) => {
        if (sectionRef.current) observer.unobserve(sectionRef.current);
      });
    };
  }, [sections]);
  
  return (
    <Container fluid className="p-0" style={{ overflowX: "hidden" }}>
      {/* 1st */}
      <div
  ref={sections.intro}
  data-section="intro"
  style={{ minHeight: "100vh",  }}
>

      <div
        className="d-flex justify-content-center"
        style={{ background: "#fdca40",  }}
      >
        <p className="p-2 m-0 text-center">
          Discover What <b>GoSeo</b> Can Do for You — Book Your Demo Today!
        </p>
      </div>
      <Container className="px-3">
        <div className="row mt-3">
          <div className="col-md-1"></div>
          <div className="col-10 d-flex justify-content-between align-items-center">
            <img src={logo} alt="profile" style={{ height: "5vh" }} />
            <div>
              <Button
                size="sm"
                style={{ borderRadius: "1.5rem", marginRight: "10px" }}
                onClick={handleGetStartedClick}
              >
                Get Started
              </Button>
              <Button
                size="sm"
                variant="secondary"
                style={{ borderRadius: "1.5rem" }}
                onClick={() => window.open("https://dashboard.goseo.in/", "_blank")}
              >
                Login
              </Button>
            </div>
          </div>

          <div className="col-md-1"></div>
        </div>
        <hr />
        <div className="row mt-5">
          <div className="col-md-1"></div>
          <div
            className="col-12 col-md-5 d-flex flex-column justify-content-center mb-4 mb-md-0"
            style={{ minHeight: "60vh" }}
          >
            <h1>
              Grow Your Website’s <br /> Organic Traffic.
            </h1>
            <p style={{ maxWidth: "500px" }}>
              Boost your Site’s ranking, engage more visitors, and convert leads
              faster with GoSEO’s powerful SEO tools and real-time optimization
              capabilities.
            </p>
            <div className="d-flex gap-2 mt-3 align-self-start">
              <button
                className="btn btn-primary"
                style={{ borderRadius: "1.5rem" }}
              >
                Book a Demo
              </button>
              <button
                className="btn btn-secondary"
                style={{
                  borderRadius: "1.5rem",
                  background: "#E8EEFC",
                  color: "#2176FF",
                  border: "1px solid #2176FF",
                }}
              >
                See Pricing
              </button>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-end">
            <img
              src={Banner}
              alt="Banner"
              style={{
                height: "60vh",
                width: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        </div>
        <div className="mt-5">
          <p
            className="text-center"
            style={{ fontSize: "1.2rem", fontWeight: "600" }}
          >
            Rank Higher, Capture Visitors, and Boost Conversions with GoSEO
            <br /> Your All-in-One SEO Solution.
          </p>
        </div>
      </Container>  
      </div>

      {/* 2nd */}
      <div
        ref={sections.tools}
        data-section="tools"
        style={{ background: "#31393C", minHeight: "100vh" }}
      >
        <p
          className="text-center px-3"
          style={{
            fontSize: "1.2rem",
            fontWeight: "600",
            color: "#fff",
            paddingTop: "5rem",
          }}
        >
          A Powerful Suite of Tools to Maximize Your SEO Success.
        </p>
        <div className="row mt-5 mx-0 align-items-center">
          {/* Left Section: Image */}
          <div className="col-12 col-md-6 d-flex justify-content-center">
            <img
              src={Banner3}
              alt="Banner"
              style={{
                height: "auto",
                maxHeight: "60vh",
                width: "100%",
                objectFit: "contain",
              }}
              className="img-fluid"
            />
          </div>

          {/* Right Section: Text */}
          <div className="col-12 col-md-6 d-flex flex-column justify-content-center px-4">
            <h2 className="text-white text-center text-md-start mb-3">
              How GoSEO Works
            </h2>
            <p
              style={{ maxWidth: "500px", color: "#fff" }}
              className="mx-auto mx-md-0 text-center text-md-start"
            >
              Why settle for basic, surface-level SEO tweaks when you can dive
              deeper? Our tool allows you to control every SEO parameter
              directly from the core – through JSON and code.
            </p>
            <p
              style={{ maxWidth: "500px", color: "#fff" }}
              className="mx-auto mx-md-0 text-center text-md-start"
            >
              Say goodbye to cookie-cutter SEO strategies and hello to advanced
              customization that adapts to your site’s unique needs.
            </p>
            <p
              style={{ maxWidth: "500px", color: "#fff" }}
              className="mx-auto mx-md-0 text-center text-md-start"
            >
              Whether you're optimizing metadata, enhancing load speeds,
              managing indexability, or crafting precise sitemaps, our platform
              gives you the direct access you've been missing.
            </p>
          </div>
        </div>
      </div>
      {/* 3rd */}
      <div
        className="m-0 p-0"
        style={{ minHeight: "100vh", overflow: "hidden" }}
      >
        <div className="container p-0">
          <div className="row">
            <div className="col-md-1 d-none d-md-block"></div>
            <div
              className="col-12 col-md-10 p-0"
              ref={sections.contact}
              data-section="contact"
              style={{ minHeight: "100vh", overflow: "hidden" }}
      
            >
              <Contactus />
            </div>
            <div className="col-md-1 d-none d-md-block"></div>
          </div>
        </div>
      </div>
      {/* 4th */}
      <div
        ref={sections.services}
        data-section="services"
        style={{ background: "#EBEBEB", height: "100vh", overflow: "hidden" }}
      >
        <h2
          className="text-center"
          style={{
            fontWeight: "600",
            paddingTop: "1rem",
          }}
        >
          Comprehensive SEO Services
        </h2>
        <p
          className="text-center"
          style={{
            fontSize: "1rem",
            maxWidth: "45vw",
            lineHeight: "1.5",
            margin: "0 auto",
            paddingBottom: "1rem",
          }}
        >
          Optimize your online presence with GoSEO. Boost visibility, drive
          traffic, and improve rankings to achieve your business goals and
          long-term growth.
        </p>

        <div className="container p-0">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col">
              <div className="row ">
                <div className="col-12 col-md-4 p-1 ">
                  <div className="card " style={{ height: "42vh" }}>
                    <div className="card-body p-0">
                      <div className="px-3 pt-3">
                        <img
                          src={icon1}
                          className="p-2"
                          alt="profile"
                          style={{
                            height: "7vh",
                            background: "#EDEDED",
                            borderRadius: ".3rem",
                          }}
                        />
                        <h5 className="mt-3">Search Engine Optimization</h5>
                        <p style={{ color: "#7D7D7D", fontSize: ".8rem" }}>
                          Enhance rankings and visibility with GoSEO’s
                          all-in-one SEO toolkit, targeting on-page and off-page
                          factors.
                        </p>
                      </div>
                      <div className="d-flex justify-content-end">
                        <img
                          src={cardimg1}
                          alt="card image"
                          style={{ height: "20vh" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4 p-1 ">
                  <div className="card " style={{ height: "42vh" }}>
                    <div className="card-body p-0">
                      <div className="px-3 pt-3">
                        <img
                          src={icon2}
                          className="p-2"
                          alt="profile"
                          style={{
                            height: "7vh",
                            background: "#EDEDED",
                            borderRadius: ".3rem",
                          }}
                        />
                        <h5 className="mt-3">Social Media Optimization</h5>
                        <p style={{ color: "#7D7D7D", fontSize: ".8rem" }}>
                          Maximize your brand's social media reach to drive
                          traffic and enhance your SEO strategy.
                        </p>
                      </div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={cardimg2}
                          alt="card image"
                          style={{ height: "20vh" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4 p-1 ">
                  <div className="card " style={{ height: "42vh" }}>
                    <div className="card-body p-0">
                      <div className="px-3 pt-3">
                        <img
                          src={icon1}
                          className="p-2"
                          alt="profile"
                          style={{
                            height: "7vh",
                            background: "#EDEDED",
                            borderRadius: ".3rem",
                          }}
                        />
                        <h5 className="mt-3">App store optimization</h5>
                        <p style={{ color: "#7D7D7D", fontSize: ".8rem" }}>
                          Optimize your app’s presence on app stores, improving
                          discoverability and driving downloads.
                        </p>
                      </div>
                      <div className="d-flex justify-content-start">
                        <img
                          src={cardimg3}
                          alt="card image"
                          style={{ height: "18vh" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
        <div className="container p-0">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col">
              <div className="row">
                <div className="col-12 col-md-6 p-1 ">
                  <div className="card " style={{ height: "32vh" }}>
                    <div className="card-body p-0">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="ms-3 pt-3">
                            <img
                              src={icon3}
                              className="p-2"
                              alt="profile"
                              style={{
                                height: "7vh",
                                background: "#EDEDED",
                                borderRadius: ".3rem",
                              }}
                            />
                            <h5 className="mt-3">SEO Analysis</h5>
                            <p style={{ color: "#7D7D7D", fontSize: ".8rem" }}>
                              Get a detailed analysis of your website’s SEO
                              performance and actionable insights to guide
                              continuous improvement.
                            </p>
                          </div>
                        </div>
                        <div className="col-6">
                          <img
                            className="mt-3"
                            src={cardimg4}
                            alt="profile"
                            style={{ height: "33vh" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 p-1 ">
                  <div className="card " style={{ height: "32vh" }}>
                    <div className="card-body p-0">
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="ms-3 pt-3">
                            <img
                              src={icon4}
                              className="p-2"
                              alt="profile"
                              style={{
                                height: "7vh",
                                background: "#EDEDED",
                                borderRadius: ".3rem",
                              }}
                            />
                            <h5 className="mt-3">Campaign Management</h5>
                            <p style={{ color: "#7D7D7D", fontSize: ".8rem" }}>
                              Plan, execute, and track digital campaigns
                              directly from GoSEO, helping you manage marketing
                              efforts with ease.
                            </p>
                          </div>
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                          <img
                            className="mt-3"
                            src={cardimg5}
                            alt="profile"
                            style={{ height: "29vh" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </div>
      {/* 5th */}
      <div
       ref={sections.intro}
        data-section="features"
        className="m-0 p-0"
        style={{ background: "#FDCA40", height: "100vh", overflow: "hidden" }}
      >
        <h2
          className="text-center mt-3 p-5 "
          style={{
            fontWeight: "600",
          }}
        >
          Explore the Power of <span style={{ color: "#2176FF" }}>GoSEO’s</span>{" "}
          Features
        </h2>

        <div className="container p-0">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col">
              <TabWithCards />
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </div>
      {/* 6th */}
      <div
        className="m-0 p-0"
        style={{ background: "#fff", height: "100vh", overflow: "hidden" }}
      >
        <h2
          className="text-center mt-3 p-4 "
          style={{
            fontWeight: "600",
          }}
        >
          Explore the Power of GoSEO’s Features
        </h2>
        <div className="container p-0">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col">
              <Verticalcard />
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default First;

import './App.css';
import First from './Landingpage/First';

function App() {
  return (
   <>
    <First/>
   </>
  );
}

export default App;

import React, { useState } from "react";
import Contact from "../Images/Contact.svg";
import { Card, Form, Button, Row } from "react-bootstrap";
const Contactus = () => {
  return (
    <div className="container pt-3">
      <div className="row pt-5 p-0">
        <div className="col">
          <h2>Ready to Rank? </h2>
          <h2> Power Up Your Website Today</h2>

          <img src={Contact} alt="profile" style={{ height: "65vh" }} />
        </div>
        <div className="col ">
          <Card className="p-3 shadow-lg" style={{ border: "none" }}>
            <Card.Body>
              <Card.Title className="mb-4">
                Start Your Free Trial with{" "}
                <span style={{ color: "#5089EB" }}>GOSEO</span> Today!
              </Card.Title>
              <Form>
                <Form.Group controlId="formName" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="FULL NAME"
                    className="custom-placeholder"
                  />
                </Form.Group>
                <Form.Group controlId="formMobile" className="mb-3">
                  <Form.Control
                    type="tel"
                    placeholder="MOBILE"
                    className="custom-placeholder"
                  />
                </Form.Group>
                <Form.Group controlId="formEmail" className="mb-3">
                  <Form.Control
                    type="email"
                    placeholder="EMAIL"
                    className="custom-placeholder"
                  />
                </Form.Group>
                <Form.Group controlId="formWebsite" className="mb-3">
                  <Form.Control
                    type="url"
                    placeholder="WEBSITE"
                    className="custom-placeholder"
                  />
                </Form.Group>
                <Form.Group controlId="formCompanyName" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="COMPANY NAME"
                    className="custom-placeholder"
                  />
                </Form.Group>
                <Form.Group controlId="formPassword" className="mb-3">
                  <Form.Control
                    type="password"
                    placeholder="CREATE PASSWORD"
                    className="custom-placeholder"
                  />
                </Form.Group>
                <Row>
                  <Form.Group controlId="formDA" className=" col mb-3">
                    <Form.Control
                      type="number"
                      placeholder="DOMAIN AUTHORITY"
                      className="custom-placeholder"
                    />
                  </Form.Group>
                  <Form.Group controlId="formPA" className=" col mb-3">
                    <Form.Control
                      type="number"
                      placeholder="PAGE AUTHORITY"
                      className="custom-placeholder"
                    />
                  </Form.Group>
                </Row>

                <Button
                  style={{ background: "#5089EB", borderRadius:"1.2rem" }}
                  type="submit"
                  className="w-100"
                >
                  Start Free Trial
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
